const floors = [
  'Palapa',
  'Rosa Platz',
  'Luftschloss',
  'Palast der Republik',
  'Neuland',
  'Seebühne',
  'Triebwerke',
  'Datscha',
  'Tubebox',
  'Salon de Baile',
  'Weidendom',
  'Karl Kutter',
  'Casino',
  'Hpttrsn',
  'Cloud Cuckoo',
  'Velvet Soup',
  'Turmbühne',
  'Tanzwüste',
  'Sonnendeck',
  'Panne Eichel',
  'Trancefloor',
  'Querfeld',
  'Subardo',
  'Dubstation',
  'Extravaganza',
  'Rootsbase',
  'Content',
  'Harbor',
  // 'Harbour',
  // 'Harbour Side',
  'Oase',
  'Workshop Hangar',
  'Outdoor',
  // 'Schubkarton',
  'Schuhkarton',
  'Räuberhöhle',
  'Tarmac',
  'Theater',
  'Toto Klub',
  'Toto Arena',
  'Tube Tresen',
  'La Ballena',
  'Kino',
  'Fer à Coudre',
  // 'Grand Palais',
  // 'Kinderspace',
].sort()

const floorDescriptions = {
  de: {
    Oase: 'Ein bunter Platz, organisiert von einem großen Netzwerk vieler unterschiedlicher Menschen. Ohne sie alle gäbe es dort kein warmes Wasser, keine kalte Mate, keine veganen Mettwurstigel, nix zu lesen, kein Klopapier, keine Workshop- und Infozelte, keine anregenden Diskussionen, keine offenen Ohren, keine wärmenden Schultern, keine Ruhezonen, kein nächtliches Feuer und keinen Kaffee am Morgen. Und weil es das alles gibt, könnt ihr abseits der anstrengenden Feierei entspannt in drei großen Bildungszelten mitmachen, vormachen, nachmachen, mitreden, nachlesen, ausreden, vorreiten, queer leben oder einfach da sein.',
    Harbor: `Analyse & Strategie: recherchieren, gründen, verbünden
<br /><br />
Keine Aktion ohne Hintergrundwissen! Hier ist der Hafen, in dem ihr erfahrt, wie man Menschen an Bord holt, Aktionen rechtlich verankert und in den Wogen des Diskurses navigiert. Ein Umschlagplatz für aktivistische Ideen und konspirative Fracht.
<br /><br />
Direkte Aktion: flitzen, flöten, Torten werfen
<br /><br />
Im Äktschn-Harbour können handgreifliche Protest-Techniken im Trockendock gelernt und verfeinert werden: Feuerlöscher mit Farbe füllen, Teleskop-Sprühdosen für Wahlplakate, aufblasbare Riesen-Skulpturen, Pyrotechnik, Aktionsklettern und politischer Tortenwurf. Aber was nutzt ein Schiff, wenn es nicht in See sticht? Die Workshops verlassen den sicheren Harbour und segeln mit Rückenwind hinein ins ravende Menschenmeer.`,
    // 'Cloud Cuckoo': "Hier geht's richtig ab",
    Content: `Die Fusion wurde aus der Idee geboren, unterschiedliche Lebens- und Feier-Realitäten zu verbinden. Es ist klar, dass es nicht nur eine einzige Wahrheit gibt, und dass wir Vielfalt suchen und pflegen müssen. Von selbst passiert Vielfalt nicht und Brücken bauen ist Handarbeit.
<br /><br />
Die Zeiten werden nicht einfacher, die politischen und wirtschaftlichen Kräfte, die sich gegen unseren Traum vom gerechten Leben stellen, stärker. Lasst uns bei aller Unterschiedlichkeit zusammen halten, um ihnen nicht das Feld zu überlassen!
<br /><br />
Unser Programm lädt ein, verschiedene Perspektiven und Blickwinkel einzunehmen. Ihr könnt Menschen treffen, die in vermeintlich hoffnungslosen Situationen weiter an eine gute Lösung glauben, die trotz Krieg, Umweltzerstörung und Rechtsruck die Hoffnung nicht aufgeben und wie wir an der Vision von einer friedlichen, respektvollen Welt festhalten. 
<br /><br />
Die Vorbereitungen für das Programm waren aufgrund der Situation von Palästina und Israel nicht einfach. Einige aus unserer Crew und von den Speaker*innen sind im Prozess ausgestiegen. Das war schmerzhaft. Wir versuchen, einem respektvollen, empathischen Umgang miteinander treu zu bleiben.
<br /><br />
Wir wollen mit unserem Programm Perspektiven erweitern, inspirieren und Verständnis füreinander stärken. Wir hoffen einen Ort zu schaffen, in dem mehrere Blickwinkel und Widerspruch und Austausch von gegensätzlichen Positionen möglich ist. Gutes Streiten ist willkommen. Lasst uns gemeinsam an einem Strang ziehen, für Menschlichkeit, Meinungsfreiheit und Verbundenheit!
<br /><br />
Nach dem Festival werden viele der Talks inklusive Übersetzung hier zu finden sein: content.kulturkosmos.de
`,
    'Toto Klub Zirkus':
      'Nimm die U-Bahn und entdecke unseren riesigen Ghetto Blaster Night Club! Fahre Rollschuh auf der legendären Toto Piste, spiele Basketball mit King Kong und ­genieße Schorlen, Shakes, Pastis und Cocktails, oder verbringe eine Nacht mit Raffaello oder Mikeangelo im Love Room des Motototels. Spiel Minigolf mit Godzilla und genieße Weißwein und französische Bouletten während du dir im Friseursalon die Haare legen lässt und tanze mit nasty B-Boys und B-Girls zum Get Down am Vinyl Desk.',
    'Cloud Cuckoo': `Wolkenkuckuck! Kings, Queens und Queers — hier könnt ihr sein, was ihr wollt! Komm in die ­wattige Wolkenwelt, in eine ­Utopie ohne Bodenhaftung. 
    Ein Ort voller überbordender ­Performances, Konzerte, DJs, schillernder Kostüme, viel ­Trashappeal und Open Minds. Willkommen zu dem Apocalyptic Drag Musical, der Oriental Drag Belly Show und dem legendären CherrYo!kie direkt vor den Toren des Cloud Cuckoo. 
    Wir feiern einen rauschenden ­Abschluss mit den Queens Against Borders und anschlie­ßender Disco Afterhour. 
    Keep it cute, Cuckoo’s!`,
  },
  en: {
    Oase: 'Ein bunter Platz, organisiert von einem großen Netzwerk vieler unterschiedlicher Menschen. Ohne sie alle gäbe es dort kein warmes Wasser, keine kalte Mate, keine veganen Mettwurstigel, nix zu lesen, kein Klopapier, keine Workshop- und Infozelte, keine anregenden Diskussionen, keine offenen Ohren, keine wärmenden Schultern, keine Ruhezonen, kein nächtliches Feuer und keinen Kaffee am Morgen. Und weil es das alles gibt, könnt ihr abseits der anstrengenden Feierei entspannt in drei großen Bildungszelten mitmachen, vormachen, nachmachen, mitreden, nachlesen, ausreden, vorreiten, queer leben oder einfach da sein.',
    Harbor: `Analysis & strategy: research, establish, ally
No action without background knowledge! This is the harbour where you can find out how to get people on board, legally anchor actions and navigate the waves of discourse. A transhipment centre for activist ideas and conspiratorial cargo.

Direct action: whizzing, fluting, throwing pies
In the Äktschn-Harbour, hands-on protest techniques can be learnt and refined in the dry dock: Filling fire extinguishers with paint, telescopic spray cans for election posters, giant inflatable sculptures, pyrotechnics, action climbing and political pie throwing. But what use is a ship if it doesn't set sail? The workshops leave the safe harbour and sail with a tailwind into the raging sea of people.`,
    Content: `Fusion was born from the idea of combining different realities of life and celebration. It is clear that there is not just one single truth, and that we must seek and cultivate diversity. However, diversity does not happen by itself and building bridges is manual work.
<br /><br />
Times are not getting any easier, and the political and economic forces that oppose our dream of a good life are getting stronger. Despite all our differences, let's stick together so that we don't leave the field to them!
<br /><br />
Our program invites you to take on different perspectives and points of view. You can meet people who continue to believe in a good solution in supposedly hopeless situations, who do not give up hope despite war, environmental destruction and the shift to the right and who, like us, hold on to the vision of a peaceful, respectful world. 
<br /><br />
The preparations for the program were not easy due to the situation in Palestine and Israel. Some of us and also the speakers dropped out. That was painful, even though we remain in contact and try to stay true to a respectful, empathetic approach. 
<br /><br />
With our program, we want to broaden perspectives, inspire and strengthen understanding for each other. We hope to create a place where multiple points of view and contradiction and exchange of opposing positions is possible. Good arguments are welcome. Let's pull together for humanity, freedom of expression and solidarity!
<br /><br />
Many of the panel discussions including translation will be made available here: content.kulturkosmos.de
`,
    'Toto Klub Zirkus':
      'Take the subway and discover with us our huge Ghetto Blaster Night Club and our traditional ­roller ­skating and karting area. Come to play with King Kong at the basketball court, drink spritz, milkshakes, pastis and cocktails and have a night with ­Raffaello or ­Mikeangelo in the Motototel Love Room. Play mini golf with ­Godzilla, eat French boulettes while drinking ­natural white wine, get a new hair cut in the barber shop and dance with the ­nasty B-boys and B-girls to the Get Down at the vinyl desk.',
    'Cloud Cuckoo':
      'Cloud Cuckoo! Kings, Queens and Queers – here you can be whatever you want! Come to the wispy cloud world, to a utopia with no grounding. A place full of exuberant performances, concerts, DJs, dazzling costumes, lots of trashappeal and open minds. Welcome to the Apocalyptic Drag Musical, the Oriental Drag Belly Show and the legendary CherrYo!kie right outside the gates of Cloud Cuckoo. We‘ll be celebrating a glittering ­finale with Queens Against Borders followed by a disco afterhour. Keep it cute Cuckoo‘s.',
  },
}

const types = [
  'Band',
  'Zeitgenössischer Zirkus',
  'Live Act',
  'DJ',
  'Talk',
  'Workshop',
  'Theater',
  'Performance',
]

const days = ['Mi', 'Do', 'Fr', 'Sa', 'So', 'Mo']
const daysOfWeek = ['So', 'Mo', 'Do', 'Mi', 'Do', 'Fr', 'Sa']

/**
 * ACHTUNG: this file must be a js file to be usaable in prepareList.js
 */

module.exports = {
  floors,
  days,
  daysOfWeek,
  types,
  floorDescriptions,
}
